<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent :title="`${promotion_type_text} 목록`" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <InformationComponent
          type="info"
          :messages="[`* 해당 줄을 드래그&드랍하시면 순서 및 활성화 여부 수정이 가능합니다.`,
                      `* ${promotion_type_text} 활성화 등록은 최대 ${maxActivateNumber}개 까지 가능합니다.`]"
        />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn
          class="mx-0"
          color="primary"
          :to="`/service/advertisement/${$route.params.promotionType}/add`"
        >
          <v-icon>add</v-icon>
          <span class="pl-2">{{ promotion_type_text }} 등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="promotion_type === 'popup'">
      <v-col cols="12">
        <v-select
          v-model="maxActivateNumber"
          :items="[0, 1, 2, 3, 4, 5]"
          label="최대 활성화 갯수"
          append-icon="expand_more"
          outlined
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <!-- <tbody v-if="!isLoading"> -->
            <draggable
              v-if="!isLoading"
              :list="items"
              tag="tbody"
              :disabled="false"
              :move="onMoveCallback"
              :clone="onCloneCallback"
              @end="onDropCallback"
            >
              <tr
                v-for="item in items"
                :key="`messages-item-${item.idx}`"
                class="grab"
              >
                <td
                  class="text-center"
                >
                  {{ (item.is_activate === 1) ? item.sequence_info : '-' }}
                </td>
                <td
                  class="text-center"
                >
                  <span :class="(item.is_activate === 0) ? `grey--text` : ''">
                    {{ (item.is_activate === 1) ? '활성화 됨' : '비활성화' }}
                  </span>
                </td>
                <td class="text-center pa-2">
                  <router-link :to="`/service/advertisement/${$route.params.promotionType}/${item.idx}`">
                    <img
                      :src="item.image_url1"
                      style="max-width: 150px;"
                    >
                  </router-link>
                </td>
                <td
                  v-show="promotion_type === 'banner'"
                  class="text-center pa-2"
                >
                  <router-link :to="`/service/advertisement/${$route.params.promotionType}/${item.idx}`">
                    <img
                      :src="item.image_url2"
                      style="max-width: 150px;"
                    >
                  </router-link>
                </td>
                <!-- <td class="text-center text-caption">
                  {{ item.is_activate === 1 ? 'Y' : 'N' }}
                </td> -->
                <!-- <td class="text-center">
                  {{ item.is_delete === 1 ? 'Y' : 'N' }}
                </td> -->
                <td class="text-center text-caption">
                  <!-- {{ item.created_at || dayjsLocalDatetime }} -->
                  {{ item.created_at || dayjsLocalDatetime }}
                </td>
              </tr>
            <!-- </tbody> -->
            </draggable>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent
          v-if="pagination.current_page"
          v-model="pagination"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import paginationLib from '../_elements/pagination/paginationLib'
import PaginationComponent from '../_elements/pagination/Pagination'
import InformationComponent from '../_elements/textbox/Information'
import Api from './api/advertisementApi'

export default {
  components: {
    draggable,
    TbodyPreRender,
    PageTitleComponent,
    PaginationComponent,
    InformationComponent
  },
  data() {
    return {
      promotion_type: '',
      promotion_type_text: '',
      isLoading: false,
      items: [],
      maxActivateNumber: 3,
      query: '',
      pagination: {},
      headers: [
        { text: '순서', align: 'center' },
        { text: '활성화 여부', align: 'center' },
        { text: 'Image', align: 'center' },
        { text: 'created at', align: 'center' }
      ]
    }
  },
  created() {
    this.setup()
  },
  methods: {
    async setup() {
      const { page, query } = this.$route.query.params || { page: 1, query: '' }
      this.promotion_type = this.$route.params.promotionType
      this.promotion_type_text = { banner: '베너', popup: '팝업' }[this.promotion_type]

      if (this.promotion_type === 'banner') {
        this.headers.splice(3, 0, { text: 'Image (Detail)', align: 'center' })
      }
      this.query = query || ''
      this.pagination.page = parseInt(page, 10) || 1
      this.getItems()
    },
    async getItems() {
      this.isLoading = true
      const { promotionType } = this.$route.params
      const { items, pagination } = await Api.getItems(
        promotionType, this.pagination.page, this.pagination.limit, this.query
      )
      this.items = items
      if (pagination) {
        this.pagination = paginationLib.paginationMapper(pagination)
      }
      if (this.promotion_type === 'popup') {
        this.maxActivateNumber = this.items.filter((item) => item.is_activate === 1).length
      }
      this.isLoading = false
    },
    onCloneCallback(item) {
      const cloneItem = JSON.parse(JSON.stringify(item))
      return cloneItem
    },
    onMoveCallback(evt) { // , originalEvent
      const item = evt.draggedContext.element
      if (item.is_deleted === 1) {
        return false
      }
      return true
    },
    async onDropCallback() { // evt, originalEvent
      const activateIdxList = []
      this.items.forEach((item, k) => {
        if (k <= (this.maxActivateNumber - 1)) {
          activateIdxList.push(item.idx)
        }
      })
      await Api.changeOrder(this.promotion_type, activateIdxList)
      this.$bus.$emit('successMessage', '순서 및 활성화 처리가 변경되었습니다')
      await this.getItems()
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
tr.grab {
  cursor: grab;
}
tr.grab:active {
  cursor: grabbing;
}
</style>
